





















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'Error',
})
export default class Error extends Vue {
  @Prop({ required: false })
  protected readonly code!: string;

  @Prop({ required: false })
  protected readonly reason!: string;

  @Prop({ required: false })
  protected readonly message!: string;

  @Prop({ required: false })
  protected readonly hideHomeButton?: any;
}
